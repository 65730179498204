.image-container {
  width: 100%; /* Ajusta a largura da div conforme necessário */
  height: 0; /* Define uma altura inicial de 0 */
  padding-bottom: 75%; /* Define a proporção de altura em relação à largura (exemplo: 4:3 = 75%) */
  position: relative;
  overflow: hidden;
}

.image-container img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover; /* Faz a imagem preencher o espaço disponível sem ser distorcida */
}
